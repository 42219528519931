<template>
  <div class="LoginPart">
    <div class="main">
      <div class="content">
        <div class="right">
          <p class="logo"><img src="./../assets/img/logo.png"></p>
          <p class="logo-title"><img src="./../assets/img/title.png"></p>
          <div>
            <el-form ref="myForm" :model="form" label-width="80px" :label-position="'top'" :rules="rules">
              <el-form-item label="" prop="username" class="login-input">
                <el-input v-model="form.username" placeholder="用户名" prefix-icon="el-icon-user"></el-input>
              </el-form-item>
              <el-form-item label="" prop="password" class="login-input">
                <el-input v-model="form.password"  type="password" placeholder="密码" prefix-icon="el-icon-lock"></el-input>
              </el-form-item>
              <el-form-item size="large">
                <el-button type="primary" @click="onSubmit"
                  style="width:72%;margin-left:4vw;margin-top:1.5vw;height:3vw;background-color:#3484e7;">登录</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import moment from "moment";
  import CryptoJS from 'crypto-js';
  const userList = require('../../public/user.json');
  export default {
    name: 'LoginPart',
    data() {
      return {
        userList: [],
        currentUser:{},
        form: {
          username: '',
          password: ''
        },
        rules: {
          username: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
            { validator: this.validateUsername, trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' }
          ]
        }
      }
    },
    created() {
      this.userList = JSON.parse(atob(userList.cusnoList));
    },
    methods: {
      validateUsername(rule, value, callback) {
        if (value.includes('+') || /[\u4e00-\u9fa5]/.test(value)) {
          callback(new Error('用户名不能包含加号"+"或中文'));
        } else {
          callback();
        }
      },
      aesEncrypt(transData, transKey, transIv) {
        const key = CryptoJS.enc.Utf8.parse(transKey);
        const iv = CryptoJS.enc.Utf8.parse(transIv);
        const encrypted = CryptoJS.AES.encrypt(transData, key, {
          iv: iv
        });
        const crypted = encrypted.toString();
        return crypted
      },
      findDataById(dataArray, cusno) {
        var matchedData = dataArray.filter(function (data) {
          return data.cusno == cusno;
        });
        return matchedData[0];
      },
      onSubmit() {
        this.$refs.myForm.validate(valid => {
          if (valid) {
          this.currentUser=this.findDataById(this.userList,this.form.password);
          
          if(!this.currentUser){
            this.$message.error('请输入正确的密码');
            return;
          }
          let nowTime = moment().unix();
          let account = this.form.username;
          let tenant = this.currentUser?.cusno
          let key = this.currentUser?.key;
          let iv = this.currentUser?.iv;
          const str = `${tenant}+${account}+${nowTime}`;
          var aesStr = this.aesEncrypt(str, key, iv);
          const sig = aesStr.replace(/\+/g, '%2B')
          let url = `https://z-plugin.qixin.com/authApi/plugin/fetch_token?tenant=${tenant}&sig=${sig}`
          axios.get(url).then((res) => {
            if (res.data.code === 200 || res.data.code ===0) {
              localStorage.setItem("token", res.data.data.token)
              this.toLogin(res.data.data.token);
            }else{
              this.$message.error(res.data.msg);
            }
        })
          } else {
            //this.$message.error('请输入正确的账号');
            return false;
          }
        });
      },
      toLogin(token) {
        let tenant = this.currentUser?.cusno;
        let returnUrl = "https://zs.jingjidanao.com/platform";
        // let returnUrl="";
        let url = `https://zs.jingjidanao.com/third-login?tenant=${tenant}&token=${token}&returnUrl=${returnUrl}`
      
        window.location.href = url;
      }
    }
  }
</script>

<style scoped lang="less">
  .main {
    width: 100vw;
    height: 100vh;
    background-image: url("./../assets/img/bg.png");
    background-size: cover;
    background-attachment: fixed;
    overflow: hidden;
    min-width:1024;
    position:absolute;

    .content {
        background-image: url("./../assets/img/login-bg.png");
        box-sizing: border-box;
        z-index: 2;
        width: 28vw;
        height:35vw;
        background-size: cover;
        position:relative ;
        left: 0;
        margin-left: 60.5%;
        top:7.2vw;
    
      .right {
        .logo {
          padding-top: 3vw;
          margin-left:4vw;
          display:block;
          img {
            width: 30%;
          }
        }
        .logo-title{
          margin-top: 3.2vw;
          margin-left:4vw;
          margin-bottom:3.5vw;
          img {
            width: 75%;
          }
        }
        .login-input {
            width:72%;
            height:3vw;
            margin-left:4vw;
        }
      }
    }
  }

  /deep/ .el-input__inner{
    height: 2.5vw;
  }

  /deep/ .el-form-item {
    margin-bottom: 0;
    margin-top: 10px;

    .el-form-item__label {
      padding-bottom: 0;
      color: #ccc;
    }
  }
// 去除某一项验证图标
/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: '';
  width: 0px;
  margin-right: 0px;
}
</style>../../public/user.js