<template>
  <div id="app">
    <LoginPart/>
  </div>
</template>

<script>
import LoginPart from "@/components/LoginPart.vue"
export default {
  name: 'App',
  components:{
    LoginPart
  }
}
</script>

<style lang="less">

</style>
